import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "order-admission"
    }}>{`Order admission`}</h1>
    <p>{`When placing one or more Orders in the Back-end System, a total Order Group is created for these, which are then distributed via the NOD Client interfaces. The order group follows a life cycle as described in `}{`[Fragments included in Part 22 - Document is provided on request]`}{`. For a mobile period ticket, this life cycle will normally be DISTRIBUTED -> ACTIVATED -> COMMITTED.
It is common to place orders to be distributed to all NOD Clients with the possibility of retrieving the order, but it is also possible to set additional requirements for the distribution based on Capabilities. For example, one can limit the distribution to NOD Clients that have a minimum screen size or Clients deployed in serviced areas.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      